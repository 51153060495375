import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoginGuard } from '@core/guards/login.guard';
import { SpinnerComponent } from './modules/spinner/spinner.component';
import { ToastComponent } from './modules/toasts/toast-component/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public title = 'dms-front';

  constructor(public readonly loginGuard: LoginGuard) {}
}
