<ng-template #successTemplate let-data>
  <div class="toast">
    <img src="/assets/icons/tost-icon/success-toast.svg" alt="Success Icon" />
    <span class="success-text">{{ data.message }}</span>
    <img
      src="/assets/icons/tost-icon/close-success.svg"
      alt="Close Icon"
      style="cursor: pointer"
      (click)="closeToast()"
    />
  </div>
</ng-template>

<ng-template #errorTemplate let-data>
  <div class="toast">
    <img src="/assets/icons/tost-icon/error-toast.svg" alt="Error Icon" />
    <span class="error-text">{{ data.message }}</span>
    <img
      src="/assets/icons/tost-icon/error-close.svg"
      alt="Close Icon"
      style="cursor: pointer"
      (click)="closeToast()"
    />
  </div>
</ng-template>

<ng-template #infoTemplate let-data>
  <div class="toast">
    <img src="/assets/icons/tost-icon/info-toast.svg" alt="Error Icon" />
    <span class="info-text">{{ data.message }}</span>
    <img
      src="/assets/icons/tost-icon/default-close.svg"
      alt="Close Icon"
      style="cursor: pointer"
      (click)="closeToast()"
    />
  </div>
</ng-template>

<ng-template #warningTemplate let-data>
  <div class="toast">
    <img src="/assets/icons/tost-icon/warning-toast.svg" alt="Error Icon" />
    <span class="info-text">{{ data.message }}</span>
    <img
      src="/assets/icons/tost-icon/default-close.svg"
      alt="Close Icon"
      style="cursor: pointer"
      (click)="closeToast()"
    />
  </div>
</ng-template>
