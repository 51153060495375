import {
  Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import {
  MatSnackBarRef, MatSnackBar, MatSnackBarModule, MAT_SNACK_BAR_DATA,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import { ToastData } from '@core/interfaces/toast.interface';
import { toastDefaultConfig } from '@core/modules/toast-config';
import { ToastService } from '@core/services/tost.service';

@Component({
  selector: 'app-toast-component',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [MatSnackBarModule],
  providers: [{ provide: MAT_SNACK_BAR_DATA, useValue: {} }],
})
export class ToastComponent implements OnInit {
  @ViewChild('successTemplate') successTemplate!: TemplateRef<any>;

  @ViewChild('errorTemplate') errorTemplate!: TemplateRef<any>;

  @ViewChild('infoTemplate') infoTemplate!: TemplateRef<any>;

  @ViewChild('warningTemplate') warningTemplate!: TemplateRef<any>;

  private snackBarRef!: MatSnackBarRef<any>;

  toastData: ToastData | null = null;

  constructor(
    private toastService: ToastService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.toastService.isToastVisible$.subscribe((isVisible) => {
      if (isVisible && this.toastData) {
        this.openToast();
      } else {
        this.closeToast();
      }
    });
    this.toastService.toast$.subscribe((toastData) => {
      this.toastData = toastData;
    });
  }

  public openToast(): void {
    if (this.toastData && this.toastData.type) {
      
      const commonSettings: MatSnackBarConfig = {
        ...toastDefaultConfig,
        data: { message: this.toastData.message },
      };

      let templateRef: TemplateRef<any>;
      let panelClass: string;

      switch (this.toastData.type) {
        case 'success':
          templateRef = this.successTemplate;
          panelClass = 'success-snackbar';
          break;
        case 'error':
          templateRef = this.errorTemplate;
          panelClass = 'error-snackbar';
          break;
        case 'info':
          templateRef = this.infoTemplate;
          panelClass = 'info-snackbar';
          break;
        case 'warning':
          templateRef = this.warningTemplate;
          panelClass = 'info-snackbar';
          break;
        default:
          return;
      }

      this.snackBarRef = this.snackBar.openFromTemplate(templateRef, {
        ...commonSettings,
        panelClass: ['mat-mdc-snack-bar-container', panelClass],
      });

      this.snackBarRef.afterDismissed().subscribe(() => {
        this.toastService.hideToast();
      });
    }
  }

  public closeToast(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }
}
