{
  "name": "dms-front",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:stage": "ng serve --configuration=stage",
    "build": "ng build && npm run sentry:sourcemaps:stage",
    "build:stage": "ng build --configuration=stage && npm run sentry:sourcemaps:stage",
    "build:production": "ng build --configuration=production",
    "watch": "ng build --watch --configuration stage",
    "test": "ng test",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix",
    "pre-commit": "lint-staged --relative && ng build",
    "sonar": "node sonar.js",
    "prepare": "husky install",
    "sentry:sourcemaps:stage": "sentry-cli sourcemaps inject --org apteka-911 --project dms-frontend dist/dms-front && sentry-cli --url https://sentry.agilie.com/ sourcemaps upload --org apteka-911 --project dms-frontend dist/dms-front"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.2",
    "@angular/cdk": "^18.2.2",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/material": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@apollo/client": "^3.0.0",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ng-select/ng-select": "^13.4.1",
    "@ngneat/until-destroy": "^10.0.0",
    "@sentry/angular": "^8.31.0",
    "@sentry/cli": "^2.36.2",
    "apollo-angular": "^7.0.2",
    "graphql": "^16",
    "leaflet": "^1.9.4",
    "leaflet-draw": "^1.0.4",
    "leaflet-routing-machine": "^3.2.12",
    "leaflet.markercluster": "^1.5.3",
    "ngx-mask": "^15.1.5",
    "rxjs": "~7.8.0",
    "sonarqube-scanner": "^3.0.1",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@types/geojson": "^7946.0.14",
    "@types/leaflet": "^1.9.4",
    "@types/leaflet-draw": "^1.0.11",
    "@types/leaflet-routing-machine": "^3.2.8",
    "@types/leaflet.markercluster": "^1.5.5",
    "@angular-devkit/build-angular": "^18.2.2",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular/cli": "^18.2.2",
    "@angular/compiler-cli": "^18.0.0",
    "@eslint/js": "^8.57.0",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "^7.14.1",
    "@typescript-eslint/parser": "^7.14.1",
    "eslint": "^8.57.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.5.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-security": "^3.0.1",
    "eslint-plugin-simple-import-sort": "^12.1.0",
    "eslint-plugin-stylelint": "^0.1.1",
    "globals": "^15.7.0",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.7",
    "prettier": "^3.3.2",
    "stylelint": "^16.6.1",
    "typescript": "~5.4.2",
    "typescript-eslint": "^7.14.1"
  },
  "husky": {
    "hooks": {
      "pre-commit": "npm run lint -- --max-warnings=0"
    }
  }
}
